@use 'sass:math';

@function _getBrightness($color) {
    @return math.div(red($color) * 299 + green($color) * 587 + blue($color) * 114, 1000);
}

@function _isDarkColor($color) {
    @return _getBrightness($color) < 128;
}

@function _getContrast($color) {
    $contrast: #000000;

    @if _isDarkColor($color) {
        $contrast: #ffffff;
    }

    @return $contrast;
}

@function _multiplyColors($color1, $color2) {
    $red: floor(math.div(red($color1) * red($color2), 255));
    $green: floor(math.div(green($color1) * green($color2), 255));
    $blue: floor(math.div(blue($color1) * blue($color2), 255));
    @return rgb($red, $green, $blue);
}

@function _calculatePrimaryColor($color, $base, $percentage) {
    @return mix($color, $base, $percentage * 1%);
}

@function _calculateAccentColor($color, $base, $saturation, $lightness) {
    $mixedColor: mix(#000000, $base, 15%);
    $saturatedColor: saturate($mixedColor, $saturation);
    $lightenedColor: lighten($saturatedColor, $lightness);
    @return $lightenedColor;
}

/* Based on generator at http://mcg.mbitson.com */
@function generate($color) {
    $lightBase: #ffffff;
    $darkBase: _multiplyColors($color, $color);

    /* beautify ignore:start */
    $colors: (
        50 : _calculatePrimaryColor($color, $lightBase, 12),
        100 : _calculatePrimaryColor($color, $lightBase, 30),
        200 : _calculatePrimaryColor($color, $lightBase, 50),
        300 : _calculatePrimaryColor($color, $lightBase, 70),
        400 : _calculatePrimaryColor($color, $lightBase, 85),
        500 : _calculatePrimaryColor($color, $lightBase, 100),
        600 : _calculatePrimaryColor($color, $darkBase, 87),
        700 : _calculatePrimaryColor($color, $darkBase, 70),
        800 : _calculatePrimaryColor($color, $darkBase, 54),
        900 : _calculatePrimaryColor($color, $darkBase, 25),
        A100 : _calculateAccentColor($color, $darkBase, 80%, 65%),
        A200 : _calculateAccentColor($color, $darkBase, 80%, 55%),
        A400 : _calculateAccentColor($color, $darkBase, 100%, 45%),
        A700 : _calculateAccentColor($color, $darkBase, 100%, 40%)
    );

    $contrast: (
        contrast: (
            50 : _getContrast(map-get($colors, 50)),
            100: _getContrast(map-get($colors, 100)),
            200: _getContrast(map-get($colors, 200)),
            300: _getContrast(map-get($colors, 300)),
            400: _getContrast(map-get($colors, 400)),
            500: _getContrast(map-get($colors, 500)),
            600: _getContrast(map-get($colors, 600)),
            700: _getContrast(map-get($colors, 700)),
            800: _getContrast(map-get($colors, 800)),
            900: _getContrast(map-get($colors, 900)),
            A100: _getContrast(map-get($colors, 'A100')),
            A200: _getContrast(map-get($colors, 'A200')),
            A400: _getContrast(map-get($colors, 'A400')),
            A700: _getContrast(map-get($colors, 'A700'))
        )
    );
    /* beautify ignore:end */

    @return map-merge($map1: $colors, $map2: $contrast);
}
