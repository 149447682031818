$desktop-simple-dialog-width: 560px;
$desktop-simple-dialog-max-width: 590px;

@mixin mat-dialog-host-layout() {
}

@mixin mat-dialog-content-layout() {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

@mixin mat-dialog-actions-layout() {
    justify-content: flex-end;
}
