@use 'apps/buyiq/src/styles/theme/colors';

$white: #ffffff;
$black: #1F282C;
$black-87-opacity: rgba($black, .87);
$black-75-opacity: rgba($black, .75);
$black-60-opacity: rgba($black, .60);
$black-54-opacity: rgba($black, .54);
$black-30-opacity: rgba($black, .30);
$black-20-opacity: rgba($black, .20);
$black-10-opacity: rgba($black, .10);
$black-04-opacity: rgba($black, .04);
$black-02-opacity: rgba($black, .02);

$light-gray: #e0e0e0;
$dark-gray: #757575;

$sale-green: #3bbc7a;
$light-green: #54c494;
$future-sale-brown: rgb(250, 235, 204);

$current-special: $sale-green;
$future-special: $future-sale-brown;

$success: #5cb85c;
$warning: #eea628;
$info: #45b8ff;

$default-text: $black-87-opacity;
$muted-text: $black-60-opacity;
$readonly-text: $black-54-opacity;
$text-shadow: $black-02-opacity;

$theme-accent: colors.$buyiq-accent-color;
$theme-muted-accent: colors.$buyiq-muted-accent-color;
$theme-medium-accent: colors.$buyiq-medium-accent-color;
$theme-warn: colors.$buyiq-warn-color;

$divider: $black-10-opacity;

$missing-thumbnail: $black-30-opacity;
$default-icon: $black-54-opacity;
$disabled-icon: $black-30-opacity;
$empty-state-icon: $black-30-opacity;

$box-shadow: $black-20-opacity;
$row-hover: $black-10-opacity;
$page-background: $light-gray;
