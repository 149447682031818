@function createMutedColor($color) {
    @return lighten(desaturate(adjust-hue($color, 1), 41.67), 53.73);
}

/* beautify ignore:start */
$accent-colors: (
    'blue': #007DB8
);

$theme-color-defaults: (
    'accent': map-get($map: $accent-colors, $key: 'blue'),
    'accent-muted': createMutedColor(map-get($map: $accent-colors, $key: 'blue')),
    'accent-medium': rgba(map-get($map: $accent-colors, $key: 'blue'), 0.75),
    'warn': #f43636
);
/* beautify ignore:end */

$buyiq-accent-color: #{var(--accent-color, map-get($theme-color-defaults, 'accent'))};
$buyiq-muted-accent-color: #{var(--accent-color-muted, map-get($theme-color-defaults, 'accent-muted'))};
$buyiq-medium-accent-color: #{var(--accent-color-medium, map-get($theme-color-defaults, 'accent-medium'))};
$buyiq-warn-color: map-get($theme-color-defaults, 'warn');
