@use './shared/colors';

/* This file should contain styles which are needed to override Angular Material defaults as they
   cannot be applied from the component's style sheet */

.filter-menu.mat-mdc-menu-panel {
    min-width: 280px;
    // The Angular Material default makes the menu extend past the bottom of the screen
    max-height: calc(100vh - 100px);
}

/* Padding is handled by the component itself */
.filter-menu .mat-mdc-menu-content,
.filter-menu .mat-menu-content:not(:empty),
.filter-menu .mat-expansion-panel-body {
    padding: 0;
}

.condition-select,
.received-note,
.vendor-select,
.page-partition-select {
    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        min-height: 35px !important;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: white;
    }

    .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
        opacity: initial;
    }

    .mat-mdc-form-field-focus-overlay {
        background-color: white !important;
    }
    .mdc-text-field--filled.mdc-text-field--disabled {
        background-color: white;
    }

    .mdc-line-ripple {
        display: none;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    .mat-mdc-form-field-infix {
        min-height: 36px !important;
    }
}

.days-between-order, .order-increase {
    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        min-height: 35px !important;
    }
}

buyiq-received-line-item {
    .quantity-column .quantity-input {
        font-size: 35px;
        max-width: 95%;
    }
}

.mat-drawer-content.mat-sidenav-content {
    display: flex;
    flex-direction: column;
    flex: 1;
}

buyiq-unread-messages-dialog {
    .mat-mdc-list-base .mat-mdc-list-item {
        border-left: 3px solid colors.$theme-accent;
        padding-left: 8px;
        margin-bottom: 4px;

        .mat-line {
            white-space: inherit;
        }
    }
}

.theme-blue .mat-toolbar.mat-primary {
    background: colors.$black !important;
}

// overwrites the default style for slide toggles
.toggle {
    .mdc-form-field {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .mdc-form-field--align-end > label {
        margin-left: 0;
    }
}

buyiq-supplier-info {
    .mat-expansion-panel-body {
        padding-left: 8px;
        padding-right: 8px;
    }
}

buyiq-settings-page {
    .radio-button {
        .mdc-form-field {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }


        .mdc-form-field--align-end > label {
            margin-left: 0;
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .suggested-order-quantity .mat-form-field-wrapper {
        padding-bottom: 14px;
    }
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
    margin-right: 16px;
}

buyiq-received-order-page {
    .mdc-text-field {
        padding: 0 8px;
    }
}
