/*
   This file should only be used for global styles which need to be applied to top-level elements
   or broadly across the application. Use the component's style sheet to define component-specific
   styles.
*/
@use 'styles/theme/theme';
@use 'styles/shared/colors';
@use 'styles/shared/dialogs';
@import 'styles/material-overrides';

@include theme.createThemes();

html,
body {
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

body {
    min-height: 100%;
    background-color: colors.$page-background;
    font-size: 14px;
}

/* Simple dialog should be used for most dialogs. This fixes the dialog size to the recommended
   width in the Material Design guidelines */
.simple-dialog {
    width: dialogs.$desktop-simple-dialog-width;
}

@media (max-width: dialogs.$desktop-simple-dialog-max-width) {
    .simple-dialog {
        width: 95%;
    }
}

/* This should be used for larger dialogs like wizards or those with a lot of information. These
   dialogs will span the majority of the screen regardless of screen size */
.full-width-dialog {
    width: 95%;
}


/* The classes below are in the global css because the video and canvas objects are created programmatically by the Quagga code.
   Since they are created this way the local scss classes cannot be used */
.viewport > canvas,
.viewport > .video {
    max-width: 100%;
    width: 100%;
}

video {
    max-width: 100%;
    width: 100%;
}

canvas.drawing,
canvas.drawingBuffer {
    position: absolute;
    left: 0;
    top: 0;
}
